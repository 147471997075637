import { Injectable } from '@angular/core';
import { ChatStore } from '../../models/chat-store';

@Injectable({
  providedIn: 'root'
})
export class ChatStoreService {
  nextID = '';

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setNextID(nextId: string) {
    this.nextID = 'chat-next-id-' + nextId;
    console.log('next id');
    console.log(this.nextID);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  set(store: ChatStore) {
    const data = JSON.stringify(store);
    localStorage.setItem(this.nextID, data);
  }

  get(): ChatStore | undefined {
    console.log('get next id');
    console.log(this.nextID);
    const data = localStorage.getItem(this.nextID) || '';
    if (data !== '') {
      try {
        return JSON.parse(data);
      } catch (e) {
        return;
      }
    }
    // eslint-disable-next-line sonarjs/no-redundant-jump
    return;
  }
}
