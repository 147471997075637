import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from './angular-material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ChatComponent } from './components/chat/chat.component';
import { MarkdownEditorComponent } from './components/markdown-editor/markdown-editor.component';
import { HeaderComponent } from './components/views/header/header.component';
import { ControlsComponent } from './components/views/controls/controls.component';
import { MessagesComponent } from './components/views/messages/messages.component';
import { SidebarComponent } from './components/views/sidebar/sidebar.component';
import { ChatRoleStyleDirective } from './directive/chat-role-style.directive';
import { MainComponent } from './components/main/main.component';
import {ChatStreamService} from "./services/chat/chat-stream.service";
import {ChatAppService} from "./services/chat-app/chat-app.service";
import {ChatService} from "./services/chat/chat.service";
import {ChatStoreService} from "./services/chat-store/chat-store.service";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {MarkdownModule} from "ngx-markdown";
import {RootComponent} from "./components/root/root.component";
import {FormsModule} from "@angular/forms";

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: 'https://apipartners.banregio.com/auth',
        realm: 'Cloud-Azure',
        clientId: 'efia'
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html',
        pkceMethod: 'S256'
      }
    });
}

@NgModule({
  declarations: [
    AppComponent,
    ChatComponent,
    ControlsComponent,
    ChatRoleStyleDirective,
    HeaderComponent,
    MainComponent,
    MarkdownEditorComponent,
    MessagesComponent,
    RootComponent,
    SidebarComponent
  ],
  imports: [
    AngularMaterialModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    KeycloakAngularModule,
    ScrollingModule,
    MarkdownModule.forRoot()
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    ChatStreamService,
    ChatAppService,
    ChatService,
    ChatStoreService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
