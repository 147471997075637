import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AzureMLModelsDeployment } from 'src/app/enums/azure-mlmodels-deployment';
import { ChatApp } from 'src/app/models/chat-app';
import { ChatMessage } from 'src/app/models/chat-message';
import { Message } from 'src/app/models/message';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  @Input() config?: ChatApp;
  @Input() title = 'NextCode';
  @Input() showWelcome = true;
  @Input() nextId = 'default';

  @Output() afterMessage: EventEmitter<ChatMessage> =
    new EventEmitter<ChatMessage>();

  finalConfig!: ChatApp;

  appDefault: ChatApp = {
    title: 'NextCode',
    showWelcome: true,
    welcome: {
      title: '👋 ¡Hola!',
      subtitle: 'Bienvenido a Next Code',
      content:
        'NextCode está diseñado para brindarte apoyo en dudas y consultas de código de forma rápida. Está en fase beta, por lo que puedes encontrarte con inconvenientes mientras lo utilizas.'
    },
    placeholder: 'Escribe tu consulta',
    showVersion: true,
    showRefresh: true,
    refresh: {
      label: 'Nueva conversación',
      icon: 'refresh'
    },
    close: {
      label: 'Cerrar sesión',
      icon: 'close'
    },
    source: 'web',
    model: AzureMLModelsDeployment.ARQUITECTURATI_XLNTV_1
  };
  messages: Message[] = [];

  constructor(
    private chatService: ChatStreamService,
    private readonly keycloak: KeycloakService
  ) {
    keycloak.keycloakEvents$.subscribe({
      next(event) {
        if (event.type === KeycloakEventType.OnTokenExpired) {
          keycloak.updateToken().then((response) => {
            if (response) {
              keycloak.getToken().then((token) => {
                chatService.setBearer(token);
              });
            }
          });
        }
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  public async ngOnInit() {
    let welcome = this.appDefault.welcome;
    let refresh = this.appDefault.refresh;
    if (this.config != undefined && this.config.welcome) {
      welcome = {
        ...this.appDefault.welcome,
        ...this.config.welcome
      };
    }
    if (this.config != undefined && this.config.refresh) {
      refresh = {
        ...this.appDefault.refresh,
        ...this.config.refresh
      };
    }
    this.finalConfig = {
      ...this.appDefault,
      ...this.config
    };
    this.finalConfig.welcome = welcome;
    this.finalConfig.refresh = refresh;

    this.chatService.model$.emit(this.finalConfig.model);

    this.chatService.setNextId(this.nextId);

    this.chatService.setBearer(await this.keycloak.getToken());
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  afterWriteMessage(message: ChatMessage) {
    this.afterMessage.emit(message);
  }
}
