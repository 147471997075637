<mat-card class="full-width mat-elevation-z24 chat-app-controls">
  <mat-card-content class="message-box">
    <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
    <form>
      <mat-form-field class="full-width" appearance="fill">
        <mat-label>
          {{ appConfig.placeholder }}
        </mat-label>
        <textarea [(ngModel)]="message" name="message" (keydown.enter)="sendMessage($event)" autofocus matInput></textarea>

        <mat-icon (click)="sendMessage($event)" matSuffix>send</mat-icon>
      </mat-form-field>
    </form>
  </mat-card-content>
</mat-card>
