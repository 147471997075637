<cdk-virtual-scroll-viewport
  #scroller
  itemSize="120"
  [class]="contentStyle"
  minBufferPx="200"
  maxBufferPx="400"
>
  <div class="welcome" *ngIf="appConfig.showWelcome">
    <h1>
      {{ appConfig.welcome?.title }}
    </h1>
    <h2>
      {{ appConfig.welcome?.subtitle }}
    </h2>
    <p class="comment">
      {{ appConfig.welcome?.content }}
    </p>
  </div>

  <div class="container">
    <div class="chatfpt">
      <ng-container *cdkVirtualFor="let item of messages; let odd = odd">
        <div
          [attr.data-user]="ChatRole.USER"
          [appChatRoleStyle]="ChatRole.USER"
          [class.odd]="odd">
          <markdown
            lineHighlight
            clipboard
            [data]="item.inputs.question"
          ></markdown>
        </div>
        <div
          [attr.data-user]="ChatRole.ASSISTANT"
          [appChatRoleStyle]="ChatRole.ASSISTANT"
          [class.odd]="odd"
          *ngIf="item.outputs.answer !== ''">
          <markdown
            lineHighlight
            clipboard
            [data]="item.outputs.answer"
          ></markdown>
        </div>
      </ng-container>
    </div>
  </div>
</cdk-virtual-scroll-viewport>
