import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChatAppService } from '../chat-app/chat-app.service';
import { ChatRole } from 'src/app/enums/chat-role';
import { ChatMessage } from 'src/app/models/chat-message';
import { AzureMLModelsDeployment } from 'src/app/enums/azure-mlmodels-deployment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private model = AzureMLModelsDeployment.ARQUITECTURATI_XLNTV_1;
  private message$ = new BehaviorSubject<ChatMessage>({
    role: ChatRole.USER,
    content: ''
  });
  getMessage$ = this.message$.asObservable();
  refresh$: EventEmitter<boolean> = new EventEmitter<boolean>();
  conversationId: string;

  model$: EventEmitter<AzureMLModelsDeployment> = new EventEmitter<AzureMLModelsDeployment>();
  source$: EventEmitter<string> = new EventEmitter<string>();

  constructor(private chatAppService: ChatAppService) {
    this.conversationId = this.chatAppService.getConversationalId();
    this.model$.subscribe((model) => {
      this.model = model;
    });
    this.chatAppService.refresh$.subscribe((refresh) => {
      this.refresh$.emit(refresh);
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  refreshConversation() {
    this.conversationId = this.chatAppService.refreshConversationalId();
  }
}
