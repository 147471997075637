<ng-container *ngIf="isLoggedIn">
  <button *ngIf="isLoggedIn" type="button" (click)="logout()">Log out</button>
  <router-outlet></router-outlet>
</ng-container>
<ng-container *ngIf="!isLoggedIn">
  <div class="row" style="margin-top: 40vh">
    <div class="grid-2-center">
      <div class="col">
        <h1 style="text-align: center;">{{ title }}</h1>

        <div class="grid-3-center">
          <div class="col" style="text-align: center;">
            <button type="button" color="primary" mat-raised-button (click)="login()">
              Iniciar sesión
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
