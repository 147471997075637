import { EventEmitter, Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { ChatStoreService } from '../chat-store/chat-store.service';
import * as dayjs from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class ChatAppService {
  protected conversationId = '';
  protected model!: string;
  protected source!: string;
  private today = dayjs();
  private expires = 120;
  private format = 'YYYY-MM-DD HH:mm:ss';

  refresh$: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private chatStoreService: ChatStoreService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  initialize() {
    this.today = dayjs();
    let control = false;
    let current = '';
    const conversationId = this.chatStoreService.get();

    if (conversationId) {
      const expires = dayjs(conversationId.expires, this.format);
      const now = dayjs();
      control = expires.isAfter(now);
      current = conversationId.conversationalId;
    }

    if (control) {
      this.conversationId = current;
    } else {
      this.conversationId = uuidv4();
    }

    this.chatStoreService.set({
      conversationalId: this.conversationId,
      expires: this.today.add(this.expires, 'm').format(this.format)
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setNextId(nextId: string) {
    this.chatStoreService.setNextID(nextId);
    this.initialize();
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  updateExpires() {
    this.today = dayjs();
    this.chatStoreService.set({
      conversationalId: this.conversationId,
      expires: this.today.add(this.expires, 'm').format(this.format)
    });
  }

  getConversationalId(): string {
    return this.conversationId;
  }

  refreshConversationalId(): string {
    this.today = dayjs();
    this.conversationId = uuidv4();
    this.refresh$.emit(true);
    this.chatStoreService.set({
      conversationalId: this.conversationId,
      expires: this.today.add(this.expires, 'm').format(this.format)
    });
    return this.conversationId;
  }

  getIdEquipoCliente(): string {
    let equipoId = localStorage.getItem('equipoIdClienteHeyChat') ?? '';
    console.log('Identificador recuperado del almacenamiento local:', equipoId);
    if (equipoId == '') {
      equipoId = uuidv4();
      localStorage.setItem('equipoIdClienteHeyChat', equipoId);
    }
    return equipoId;
  }
}
