import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChatMessage } from 'src/app/models/chat-message';
import { ChatHistory } from 'src/app/models/chat-history';
import { ChatAppService } from '../chat-app/chat-app.service';
import { ChatResponseStream } from '../../models/chat-response-stream';
import { ChatRole } from 'src/app/enums/chat-role';
import { Observer } from 'rxjs';
import { Message } from 'src/app/models/message';
import { AzureMLModelsDeployment } from 'src/app/enums/azure-mlmodels-deployment';

@Injectable({
  providedIn: 'root'
})
export class ChatStreamService {
  private model = AzureMLModelsDeployment.ARQUITECTURATI_XLNTV_1;
  conversationId = '';

  message$ = new BehaviorSubject<ChatMessage>({
    role: ChatRole.ASSISTANT,
    content: ''
  });
  message = {} as Message;
  chatHistory$ = new BehaviorSubject<ChatHistory[]>([]);
  response$ = new BehaviorSubject<ChatResponseStream>({
    content: '',
    id_complition: '',
    stream: false
  });

  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showFeedbackBox$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  refresh$: EventEmitter<boolean> = new EventEmitter<boolean>();
  model$: EventEmitter<AzureMLModelsDeployment> = new EventEmitter<AzureMLModelsDeployment>();
  sending$: EventEmitter<boolean> = new EventEmitter<boolean>();
  nextId = '';

  bearerToken: string = '';

  constructor(private chatAppService: ChatAppService) {
    this.model$.subscribe((model: AzureMLModelsDeployment) => {
      this.model = model;
    });
    this.chatAppService.refresh$.subscribe((refresh: boolean) => {
      this.refresh$.emit(refresh);
    });
    this.chatHistory$.subscribe((messages: ChatHistory[]) => {
      this.message.chat_history = [...messages];
    });
  }

  setNextId(nextId: string): void {
    this.nextId = nextId;
    this.chatAppService.setNextId(this.nextId);
    this.conversationId = this.chatAppService.getConversationalId();
  }

  setBearer(bearer: string): void {
    this.bearerToken = bearer;
  }

  refreshConversation(): void {
    this.conversationId = this.chatAppService.refreshConversationalId();
  }

  send(content: string): Observable<ChatResponseStream> {
    this.message = {
      ...this.message,
      question: content,
    };
    this.write(content, ChatRole.USER);
    this.showFeedbackBox$.next(false);
    this.sending$.emit(true);
    this.chatAppService.updateExpires();

    return new Observable<ChatResponseStream>((observer) => {
      const controller = new AbortController();
      const requestOptions: RequestInit = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'text/event-stream',
          Authorization: `Bearer ${this.bearerToken}`
        },
        body: JSON.stringify(this.message),
        signal: controller.signal
      };

      const handleResponse = async (response: Response) => {
        if (response.ok) {
          const reader = response.body?.getReader();
          if (!reader) throw new Error('Failed to read response');
          const decoder = new TextDecoder('utf-8');
          let chunk = '';

          for (;;) {
            const { done, value } = await reader.read();
            if (done) break;
            chunk += decoder.decode(value, { stream: true });

            while (chunk.includes('\n')) {
              const line = chunk.substring(0, chunk.indexOf('\n'));
              chunk = chunk.slice(chunk.indexOf('\n') + 1);

              if (!line.startsWith('data: ')) {
                continue;
              }

              if (line.includes('[DONE]')) {
                this.showFeedbackBox$.next(true);
                this.loading$.next(false);
                return;
              }

              try {
                const data = JSON.parse(line.slice(6));
                observer.next({ content: data.answer, id_complition: '' });
              } catch (e) {
                console.log('Error parsing line:', line);
              }
            }
          }

          this.showFeedbackBox$.next(true);
          this.loading$.next(false);
          observer.complete();
          reader.releaseLock();
        } else {
          console.log('error');
        }
      };

      fetch('/api/fpt/chat-prompt-flow/chat', requestOptions)
        .then(handleResponse)
        .catch((error) => {
          console.log(error);
          observer.error(error);
          this.loading$.next(false);
        });

      return () => controller.abort();
    });
  }

  private async processResponse(
    response: Response,
    observer: Observer<ChatResponseStream>
  ): Promise<void> {
    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('Failed to read response');
    }
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }
      if (value) {
        const lines = decoder.decode(value);
        this.processResponseLines(lines, observer);
      }
    }

    observer.complete();
    reader.releaseLock();
  }

  private processResponseLines(
    lines: string,
    observer: Observer<ChatResponseStream>
  ): void {
    console.log('---lines---');
    console.log(lines);
    console.log('---lines split---');
    console.log(lines.split('\n'));
    console.log('----end----');

    for (const line of lines.split('\n')) {
      if (!line.startsWith('data: ')) {
        continue;
      }
      if (line.includes('[DONE]')) {
        this.showFeedbackBox$.next(true);
        this.loading$.next(false);
        return;
      }

      const newLine = line.slice(6);
      try {
        const parser = JSON.parse(newLine);

        if (
          !(
            parser.choices[0] &&
            parser.choices[0].delta &&
            parser.choices[0].delta.content
          )
        ) {
          continue;
        }

        const data = {
          id_complition: parser.id,
          content: parser.choices[0].delta.content,
          stream: true
        };
        observer.next(data);
      } catch (e) {
        console.log('Error parsing line:', newLine);
      }
    }
  }

  private handleErrorResponse(
    reader: ReadableStreamDefaultReader<Uint8Array>,
    observer: Observer<ChatResponseStream>
  ): void {
    this.loading$.next(false);
    reader.read().then(({ done, value }) => {
      if (!done && value) {
        const lines = new TextDecoder().decode(value).split('\n');
        if (lines.length > 0) {
          const error = JSON.parse(lines[0]);
          observer.error(error);
        }
      } else {
        observer.error({
          detail: 'Ha ocurrido un error'
        });
      }
    });
  }

  getConversaciones(): Message[] {
    return JSON.parse(localStorage.getItem('conversations') || '[]');
  }

  /**
   * Utilizado por messages.component en líneas 122 y 132
   */
  write(content: string, role: ChatRole): void {
    const message: ChatMessage = {
      role: role,
      content: content
    };

    this.message$.next(message);
  }
}
