import { Component, Input, OnInit, SkipSelf } from '@angular/core';
import { ChatApp } from 'src/app/models/chat-app';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  @Input() appConfig!: ChatApp;
  appVersion: string;
  refreshLabel: string;
  refreshIcon: string;
  closeSesionLabel: string;
  closeSesionIcon: string;

  constructor(@SkipSelf() private chatService: ChatStreamService, private readonly keycloak: KeycloakService) {
    this.appVersion = '';
    this.refreshIcon = '';
    this.refreshLabel = '';
    this.closeSesionLabel = '';
    this.closeSesionIcon = '';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  ngOnInit() {
    this.refreshLabel = this.appConfig.refresh?.label || '';
    this.refreshIcon = this.appConfig.refresh?.icon || '';
    this.closeSesionLabel = this.appConfig.close?.label || '';
    this.closeSesionIcon = this.appConfig.close?.icon || '';
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  doRefreshConversation() {
    this.chatService.refreshConversation();
  }

  logout() {
    this.keycloak.logout();
  }
}
