import {
  Component,
  EventEmitter,
  Input,
  Output,
  SkipSelf
} from '@angular/core';
import { ChatRole } from 'src/app/enums/chat-role';
import { FeedbackResponse } from 'src/app/enums/feedback-response';
import { ChatApp } from 'src/app/models/chat-app';
import { ChatMessage } from 'src/app/models/chat-message';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.scss']
})
export class ControlsComponent {
  @Input() appConfig!: ChatApp;
  @Output() afterWriteMessage: EventEmitter<ChatMessage> =
    new EventEmitter<ChatMessage>();
  message = '';
  loading = false;

  readonly FeedbackResponse = FeedbackResponse;

  constructor(@SkipSelf() private chatService: ChatStreamService) {
    this.chatService.response$.subscribe((text) => {
      if (text.content !== '') {
        this.chatService.write(text.content, ChatRole.ASSISTANT);
      }
    });
    this.chatService.loading$.subscribe((loading) => (this.loading = loading));
  }

  sendMessage(event: Event | MouseEvent): void {
    if (this.loading) {
      return;
    }
    event.preventDefault();
    this.loading = true;
    const messageToSend = this.message.trim();
    this.message = '';
    this.afterWriteMessage.emit({
      role: ChatRole.USER,
      content: this.message
    });
    this.chatService.send(messageToSend).subscribe(
      (response) => {
        this.chatService.write(response.content, ChatRole.ASSISTANT);
      },
      (error) => {
        console.error(error.detail);
      }
    );
  }
}
