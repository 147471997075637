import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-markdown-editor',
  templateUrl: './markdown-editor.component.html',
  styleUrls: ['./markdown-editor.component.scss']
})
export class MarkdownEditorComponent {
  markdownContent = '';
  renderedMarkdown = '';

  renderMarkdown() {
    this.renderedMarkdown = this.markdownContent;
  }
}
