<mat-toolbar color="primary" class="mat-elevation-z24">
    {{ appConfig.title }}

    <div class="app-version" *ngIf="appConfig.showVersion">
      v{{ appVersion }}
    </div>

    <button [matTooltip]="refreshLabel" mat-icon-button (click)="doRefreshConversation()">
      <mat-icon>{{refreshIcon}}</mat-icon>
    </button>

  <button [matTooltip]="closeSesionLabel" mat-icon-button (click)="logout()">
    <mat-icon>{{closeSesionIcon}}</mat-icon>
  </button>
</mat-toolbar>
