import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SkipSelf,
  ViewChild
} from '@angular/core';
import { ChatRole } from 'src/app/enums/chat-role';
import { ChatApp } from 'src/app/models/chat-app';
import { ChatHistory } from 'src/app/models/chat-history';
import { ChatMessage } from 'src/app/models/chat-message';
import { ChatStreamService } from 'src/app/services/chat/chat-stream.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent implements OnInit {
  @ViewChild('scroller') scroller!: CdkVirtualScrollViewport;
  @Input() appConfig!: ChatApp;
  @Input() refresh = false;
  @Output() afterWriteMessage: EventEmitter<ChatMessage> =
    new EventEmitter<ChatMessage>();

  markdownContent = '';
  renderedMarkdown = '';

  endOfStringRegExp = /\[done\]/gi;

  messages: ChatHistory[] = [];
  loading = false;
  contentStyle = 'content';
  alreadyUsed = false;

  readonly ChatRole = ChatRole;

  constructor(
    @SkipSelf() private chatService: ChatStreamService,
    private cdfRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.chatService.showFeedbackBox$.subscribe((loading) => {
      this.alreadyUsed = loading;
    });
    this.chatService.sending$.subscribe(() => {
      setTimeout(() => {
        this.scroller.scrollTo({
          bottom: 0,
          behavior: 'smooth'
        });
      }, 100);
    });
    let lastAssistantMessage: ChatHistory | null = null;

    this.chatService.message$.subscribe((message: ChatMessage) => {
      if (message.content === '') {
        return;
      }

      if (message.role === ChatRole.ASSISTANT) {
        if (lastAssistantMessage) {
          lastAssistantMessage.outputs.answer += message.content;
          this.cdfRef.detectChanges();
          this.chatService.chatHistory$.next(this.messages);
        }
      } else {
        lastAssistantMessage = {
          inputs: {
            question: this.chatService.message.question,
          },
          outputs: {
            answer: ''
          }
        } as ChatHistory;

        this.messages = [
          ...this.chatService.message.chat_history,
          lastAssistantMessage
        ];
      }

      setTimeout(() => {
        this.scroller.scrollTo({
          bottom: 0,
          behavior: 'auto'
        });
      }, 0);
      setTimeout(() => {
        this.scroller.scrollTo({
          bottom: 0,
          behavior: 'auto'
        });
      }, 50);
    });

    this.chatService.refresh$.subscribe((refresh: boolean) => {
      if (refresh === true) {
        this.messages = [];
        this.alreadyUsed = false;
      }
    });
  }

  renderMarkdown(): void {
    this.renderedMarkdown = this.markdownContent;
  }
}
