import { Directive, Input, ElementRef, Renderer2 } from '@angular/core';
import { ChatRole } from '../enums/chat-role';

@Directive({
  selector: '[appChatRoleStyle]'
})
export class ChatRoleStyleDirective {
  @Input() set appChatRoleStyle(role: ChatRole) {
    // Define las clases de estilo según el valor del rol
    let classes = '';
    switch (role) {
      case ChatRole.USER:
        classes = 'from-me';
        break;
      case ChatRole.ASSISTANT:
        classes = 'from-them';
        break;
      case ChatRole.SYSTEM:
        classes = ''; // Puedes asignar 'from-them' o el valor que prefieras para SYSTEM.
        break;
      default:
        classes = ''; // Manejo de valores no válidos o cualquier otro caso.
        break;
    }

    // Elimina todas las clases de estilo actuales antes de agregar las nuevas
    this.renderer.removeClass(this.elementRef.nativeElement, 'from-me');
    this.renderer.removeClass(this.elementRef.nativeElement, 'from-them');
    // Agrega las clases al elemento
    this.renderer.addClass(this.elementRef.nativeElement, classes);
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
}
